import React, { ReactElement } from "react";
import { ButtonProps, Button } from "semantic-ui-react";
import moment, { Moment } from "moment-timezone";

export const renderGridElements = (
  col: number | undefined,
  element: ReactElement,
  keyName: string,
) => {
  const generatedArray = Array(col)
    .map((_, i) => i * i)
    .fill(1);
  return generatedArray.map((val, idx) => {
    const key = `${idx}-${keyName}-loader`;
    return <React.Fragment key={key}>{element}</React.Fragment>;
  });
};

export const SemanticButtonCP: React.FC<ButtonProps> = ({
  children,
  ...props
}) => <Button {...props}>{children}</Button>;

export const ConvertDateToUTC = (date: any, format: string) => {
  const jsDate = new Date(date);
  return moment.utc(jsDate).format(`${format}`);
};

export const isSameDay = (dateFrom?: Moment, dateTo?: Moment) => {
  if (dateFrom && dateTo) {
    const date1 = ConvertDateToUTC(dateFrom, "DD");
    const date2 = ConvertDateToUTC(dateTo, "DD");
    if (date1 === date2) {
      return true;
    }
  }
  return false;
};
